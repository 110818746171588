<template>
  <div style="margin-left: 16px">
    <a-button-group>
      <a-button icon="edit" size="small" @click="updateModalVisible = true">编辑</a-button>
      <a-popconfirm title="确定删除吗" @confirm="destroy">
        <a-button type="danger" icon="delete" size="small">删除</a-button>
      </a-popconfirm>
    </a-button-group>

    <EditFollowUpRecord v-model="updateModalVisible" :item="item" @update="update" @cancel="updateModalVisible = false" />
  </div>
</template>

<script>
import { leadFollowUpRecordDestroy } from "@/apis/data";

export default {
  components: {
    EditFollowUpRecord: () => import("./EditFollowUpRecord"),
  },
  props: ["item"],
  data() {
    return {
      updateModalVisible: false,
    };
  },
  methods: {
    update(item) {
      this.$emit("update", item);
    },
    destroy() {
      leadFollowUpRecordDestroy({ id: this.item.id }).then(() => {
        this.$message.success("删除成功");
        this.$emit("destroy");
      });
    },
  },
};
</script>

<style scoped></style>
